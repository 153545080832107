<template>
  <div id="app" >
      <TopView class="top"></TopView>
      <router-view></router-view>
  </div>
</template>

<script>
import TopView from './views/TopView.vue'
export default {
  name: 'App',
  components: {
    TopView
  },
  mounted () {
    this.setRem()
  },
  methods: {
    setRem () {
      /* eslint-disable */
      let uiW = 72.16
      // vav
      // 获取屏幕的宽度
      let clientWidth = document.documentElement.clientWidth || document.body.clientWidth

      // 通过js动态改变html根节点字体大小
      let html_ = document.getElementsByTagName('html')[0];
      html_.style.fontSize = (clientWidth / uiW) + 'px';
    }
    // window.onresize 浏览器被重置大小执行事件
    // window.onresize = setRem;
    
  }
}
</script>

<style lang="less">
*{
  padding: 0;
  margin: 0;
}
#app{
  position: relative;
}
.top{
  text-align: center;
  position: absolute;
  z-index: 3;
}
</style>
