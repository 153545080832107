<template>
  <section class="home">
    <div class="home_top">
      <img class="home_top_img" mode="widthFix" src="../assets/home/位图.png" alt />
      <div class="home_top_left">
        <img src="@/assets/zujiangjun.png" alt />
      </div>
      <div class="home_top_right">
        <img src="@/assets/home/erweima@2x.png" alt />
        <img src="@/assets/home/duihua-2@2x.png" alt />
      </div>

      <div class="home_top_middle">欢迎来到租将军</div>
      <div class="home_top_middle2">租将军，租物好选择</div>
      <div class="home_top_middle3">Zu Jiang Jun Good choice for rent</div>

      <div class="home_top_bottom">
        <img src="@/assets/zu.png" alt="">
        支付宝扫一扫
      </div>
    </div>

    <div class="home_middle">
      <!-- 1 -->
      <div>
        <div class="home_middle_security">
          <div class="home_middle_shadow">
            <div style="text-align: center">
              <img src="@/assets/home/fengkong-2@2x.png" alt />
            </div>
            <span class="home_middle_span">全方位风控系统</span>
            <span class="home_middle_span2">平台建立风控规则，年龄、 高危地区、地址等因素及定位等方法</span>
          </div>

          <div class="home_middle_shadow">
            <div style="text-align: center">
              <img src="@/assets/home/1123.png" alt />
            </div>
            <span class="home_middle_span color_blue">平台内商户权益保护</span>
            <span class="home_middle_span2 color_blue">我们对商家权益高度重视，平台将以中国人保财险为每一位商家权益保驾护航</span>
          </div>

          <div class="home_middle_shadow">
            <div style="text-align: center">
              <img src="@/assets/home/fuwu@2x.png" alt />
            </div>
            <span class="home_middle_span">客户专属服务</span>
            <span class="home_middle_span2">平台所有商家享受1小时内回复，且实行“一对多”的服务，同时承诺服务效率和质量</span>
          </div>

          <div class="home_middle_shadow">
            <div style="text-align: center">
              <img src="@/assets/home/wuliu@2x.png" alt />
            </div>
            <span class="home_middle_span">贴心物流服务</span>
            <span class="home_middle_span2">所有用户在租将军平台交易成功的每一笔订单，都将由顺丰进行配送</span>
          </div>
        </div>
      </div>

      <!-- 2 -->
      <div class="dynamic">
        <img src="@/assets/home/编组 4@2x.png" alt />

        <div class="dynamic_dynamic">Dynamic</div>

        <div class="dynamic_rent">租将军动态</div>

        <div class="dynamic_box">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img mode="widthFix" src="@/assets/home/位图@2x.png" alt />
                <div>
                  <div class="dynamic_box_new">租将军构建新租赁场景</div>
                  <div class="dynamic_box_day">在互联网日渐参透下，租赁方式的观念与思维都在发生转变。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/home/位图@4x.png" alt />
                <div>
                  <div class="dynamic_box_new">以租代购是虚荣还是“轻消费”</div>
                  <div class="dynamic_box_day">在很长一段时间里，社会主流消费观所信奉的都是“买不起才租”。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/home/位图@3x.png" alt />
                <div>
                  <div class="dynamic_box_new">iPhone14真机确认CAD渲染图</div>
                  <div class="dynamic_box_day">这次来的不是渲染图，而是工厂车间流出的CAD图，可信度比较靠谱。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/home/位图@1x.png" alt />
                <div>
                  <div class="dynamic_box_new">苹果大号iPhone14曝光</div>
                  <div class="dynamic_box_day">结合目前最新的消息，今年iPhone14系列依旧有四款产品。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <img mode="widthFix" src="@/assets/home/位图@2x.png" alt />
                <div>
                  <div class="dynamic_box_new">租将军构建新租赁场景</div>
                  <div class="dynamic_box_day">在互联网日渐参透下，租赁方式的观念与思维都在发生转变。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/home/位图@4x.png" alt />
                <div>
                  <div class="dynamic_box_new">以租代购是虚荣还是“轻消费”</div>
                  <div class="dynamic_box_day">在很长一段时间里，社会主流消费观所信奉的都是“买不起才租”。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/home/位图@3x.png" alt />
                <div>
                  <div class="dynamic_box_new">iPhone14真机确认CAD渲染图</div>
                  <div class="dynamic_box_day">这次来的不是渲染图，而是工厂车间流出的CAD图，可信度比较靠谱。</div>
                </div>
              </div>
              <div class="swiper-slide">
                <img src="@/assets/home/位图@1x.png" alt />
                <div>
                  <div class="dynamic_box_new">苹果大号iPhone14曝光</div>
                  <div class="dynamic_box_day">结合目前最新的消息，今年iPhone14系列依旧有四款产品。</div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <!-- 如果需要滚动条 -->
            <!-- <div class="swiper-scrollbar"></div> -->
          </div>
        </div>
      </div>

      <!-- 3 -->
      <div class="about">
        <div class="about_about">About</div>
        <div class="about_rent">关于租将军</div>
        <div class="about_box">
          <div>
            <img src="@/assets/home/suozaichengshi@2x.png" alt />
            <div class="about_box_city">
              <div>
                200
                <span>+</span>
              </div>
            </div>
            <div class="about_box_city2">业务覆盖城市200多个</div>
          </div>
          <div>
            <img src="@/assets/home/he_57hangyefenlei@2x.png" alt />
            <div class="about_box_city">
              <div>
                300
                <span>+</span>
              </div>
            </div>
            <div class="about_box_city2">已渗透行业至300多个</div>
          </div>
          <div>
            <img src="@/assets/home/dingdan-2@2x.png" alt />
            <div class="about_box_city">
              <div>
                10000
                <span>+</span>
              </div>
            </div>
            <div class="about_box_city2">订单量突破一万以上</div>
          </div>
          <div>
            <img src="@/assets/home/leimu@2x.png" alt />
            <div class="about_box_city">
              <div>
                600
                <span>+</span>
              </div>
            </div>
            <div class="about_box_city2">商品类目超过600个</div>
          </div>
        </div>
      </div>

      <!-- 4 -->
      <div class="middle_Cooperation">
        <img src="@/assets/home/4@2x.png" alt />
        <div class="middle_partner1">Cooperation</div>
        <div class="middle_partner2">合作伙伴</div>
      </div>
    </div>

    <Bottom class="bottom"></Bottom>

  </section>
</template>

<script>
import Bottom from '@/components/Bottom.vue'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  name: 'HomeView',
  components: { Bottom },
  data () {
    return {}
  },
  methods: {},
  mounted () {
    /* eslint-disable */
    new Swiper('.swiper-container', {
      direction: 'horizontal', // 垂直切换选项
      loop: true, // 循环模式选项
      loopedSlides: 8,
      autoplay: {
        delay: 1200,
        stopOnLastSlide: false,
        disableOnInteraction: true
      },
      slidesPerView: 4, // 100%宽度情况下，显示4个slide，（原理就是设置每个slide的宽度为25%）
      spaceBetween: 20,
      //spaceBetween : '10%',按container的百分比
      // 如果需要分页器
      pagination: {
        // el: '.swiper-pagination'
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }

      // 如果需要滚动条
      // scrollbar: {
      //   el: '.swiper-scrollbar'
      // }
    })
  }
}
</script>

<style lang="less">
.home_bottom {
  color: #b9b8b8;
  width: 100%;
  height: 16.3rem;
  // background: #979797;
  background: #333;
  border: 1px solid #979797;
  // text-align: center;
  position: relative;
  .home_bottom_top {
    width: 100%;
    height: 99%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: auto;
    font-size: 0.85rem;
    span:hover{
      cursor:pointer;
    }
    .bottom_left{
      flex: 1;
      // background-color: #fff;
      position: relative;
      img{
        width: 2.2rem;
        height: 2.35rem;
        position: absolute;
        top: 6.2rem;  
        // top: calc(50% - 5.7rem);
        left: 12.5rem;
      }
      span:nth-child(1){
        font-size: 0.8rem;
        position: absolute;
        top: 5.85rem;
        left: 16.4rem;
        
      }
      span:nth-child(2){
        font-size: 1.2rem;
        position: absolute;
        top: 7.3rem;
        left: 16.4rem;
      }
    }
    .bottom_right{
      flex: 1;
      font-size: 0.9rem;
      color: #fff;
      .right_one{
        margin: 3.5rem 0 0 0.5rem;
        flex-direction: column;
        span{
          margin-right: 3.25rem;
          a{
            color: #fff;
          }
        }
      }
      .right_two{
        margin: 2.3rem 0 0 0.5rem;
      }
      .right_three{
        margin: 0.8rem 0 0 0.5rem;
      }
      .right_two,.right_three:hover{
        cursor:pointer;
      }
    }
  }
  .home_bottom_bot {
    width: 100%;
    padding: 1.1rem 0;
    position: absolute;
    bottom: 0;
    background-color: #000;
    div {
      font-size: 0.9rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffffd7;
      // line-height: 2.2rem;
      text-align: center;
      a{
        color: #ffffffd7;
      }
    }
  }
}

.home_middle {
  > div:nth-child(1) {
    padding: 5rem 0;
    .home_middle_security {
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-evenly;
      .home_middle_shadow {
        transition-duration: 0.5s; //停留时间显示
        color: #333333;
      }
      .home_middle_shadow:hover {
        // -webkit-box-shadow: #ccc 0px 10px 10px;
        // -moz-box-shadow: #ccc 0px 10px 10px;
        // box-shadow: #ccc 0px 10px 10px;
        color: #5b9fff !important;
        cursor:pointer;
      }
      div {
        width: 9rem;
        img {
          width: 2.5rem;
          height: 2.5rem;
          margin-bottom: 1.5rem;
        }
        .home_middle_span {
          // width: 5.55rem;
          height: 1.1rem;
          font-size: 0.8rem;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          // color: #333333;
          line-height: 1.1rem;
          display: block;
          text-align: center;
        }
        .home_middle_span2 {
          width: 9rem;
          height: 2.55rem;
          font-size: 0.6rem;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          // color: #333333;
          line-height: 0.85rem;
          display: block;
          text-align: center;
        }
      }
    }
  }
  .dynamic {
    // width: 1440px;
    // height: 721px;
    position: relative;
    img {
      width: 100%;
    }
    .dynamic_dynamic {
      width: 10rem;
      height: 3.25rem;
      font-size: 2.4rem;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #333333;
      line-height: 3.25rem;
      position: absolute;
      top: 9.71%;
      left: 4.86%;
    }
    .dynamic_rent {
      // width: 5.45rem;
      height: 1.5rem;
      font-size: 1.1rem;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #333333;
      line-height: 1.5rem;
      position: absolute;
      top: 20.11%;
      left: 4.86%;
    }
    .dynamic_box {
      width: 92%;
      height: 55%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      // background-color: #fff;
      position: absolute;
      top: 32.59%;
      left: 4.86%;
      .dynamic_swiper {
        z-index: 999;
      }
      .swiper-slide {
        // margin: 1rem;
        background-color: #fff;
        > div {
          .dynamic_box_new {
            width: 13.8rem;
            height: 1.25rem;
            font-size: 0.9rem;
            font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
            font-weight: 600;
            color: #333333;
            line-height: 1.25rem;

            margin: 1.2rem 0 0.6rem 0.6rem;
          }
          .dynamic_box_day {
            width: 13.8rem;
            height: 2rem;
            font-size: 0.7rem;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #999999;
            line-height: 1rem;
            margin: 0 0 1rem 0.6rem;
          }
          .dynamic_box_look {
            height: 0.85rem;
            font-size: 0.6rem;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #999999;
            line-height: 0.85rem;
            margin-left: 0.6rem;
          }
        }
      }
    }
  }
  .about {
    width: 100%;
    height: 25.55rem;
    position: relative;
    .about_about {
      width: 10rem;
      height: 3.25rem;
      font-size: 2.4rem;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #333333;
      line-height: 3.25rem;
      position: absolute;
      top: 9.71%;
      left: 4.86%;
    }
    .about_rent {
      // width: 5.5rem;
      height: 1.5rem;
      font-size: 1.1rem;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #333333;
      line-height: 1.5rem;
      position: absolute;
      top: 20.11%;
      left: 4.86%;
      margin-top: 1rem;
    }
    .about_box {
      width: 92%;
      height: 55%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      // background-color: #fff;
      position: absolute;
      top: 32.59%;
      left: 4.86%;
      > div {
        width: 15rem;
        height: 8.9rem;
        // width: 20%;
        // height: 56%;
        background: #f4f5f6;
        border-radius: 0.1rem;
        img {
          margin: 1rem 0 0 1rem;
          width: 1.7rem;
          height: 1.45rem;
        }
        .about_box_city {
          height: 3.5rem;
          font-size: 2.5rem;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #333333;
          line-height: 3.5rem;
          // margin-left: 9rem;
          // float: right;
          margin-top: 1.15rem;
          position: relative;
          span {
            display: inline-block;
            font-size: 1.1rem;
            font-weight: 900;
          }
          > div {
            display: inline-block;
            position: absolute;
            right: 0.6rem;
          }
        }
        .about_box_city2 {
          // width: 6.75rem;
          height: 1rem;
          font-size: 0.7rem;
          font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
          font-weight: 400;
          color: #666666;
          line-height: 1rem;
          margin-left: 7.65rem;
        }
      }
    }
  }
  .middle_Cooperation {
    position: relative;
    img {
      width: 100%;
    }
    .middle_partner1 {
      // width: 14.15rem;
      height: 3.25rem;
      font-size: 2.4rem;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #333333;
      line-height: 3.25rem;
      position: absolute;
      top: 3.5rem;
      left: 3.5rem;
    }
    .middle_partner2 {
      // width: 4.35rem;
      height: 1.5rem;
      font-size: 1.1rem;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #333333;
      line-height: 1.5rem;
      position: absolute;
      top: 7.25rem;
      left: 3.5rem;
    }
  }
}
* {
  margin: 0;
}
.home {
  width: 72rem;
  // height: 181.2rem;
  margin: auto;
  .bottom{
    width: 72rem;
    position: absolute;
    bottom: -16.3rem;
  }
}
a {
  text-decoration: none;
}
.home_top {
  // width: 100%;
  // height: 40rem;
  color: #ffffff;
  position: relative;
}
.home_top_img {
  width: 100%;
  // height: 100%;
}
.home_top_left {
  position: absolute;
  top: 1.3rem;
  left: 1.5rem;
  // top: 3.25%;
  // left: 2.08%;
  img {
    width: 5.6rem;
    height: 1.9rem;
  }
}
.home_top_right {
  position: absolute;
  top: 1.8rem;
  right: 3rem;
  // top: 4.5%;
  // right: 4.17%;
  img {
    width: 1.1rem;
    height: 1rem;
  }
  img:nth-child(1) {
    margin-right: 3rem;
    width: 1rem;
  }
}
.home_top_middle {
  width: 9.8rem;
  height: 1.9rem;
  font-size: 1.4rem;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  line-height: 1.9rem;
  position: absolute;
  top: 12.1rem;
  left: 5.5rem;
  // top: 30.25%;
  // left: 7.64%;
}
.home_top_middle2 {
  height: 2.9rem;
  font-size: 2.1rem;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  line-height: 2.9rem;
  position: absolute;
  top: 14.6rem;
  left: 5.5rem;
}
.home_top_middle3 {
  height: 1.5rem;
  font-size: 1.1rem;
  font-family: AlibabaPuHuiTi-Light, AlibabaPuHuiTi;
  font-weight: 300;
  line-height: 1.5rem;
  letter-spacing: 0.15rem;
  position: absolute;
  top: 17.6rem;
  left: 5.5rem;
}
.home_top_bottom {
  width: 6.3rem;
  font-size: 0.9rem;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.15rem;
  position: absolute;
  top: 30rem;
  right: 3.1rem;
  // bottom: 5%;
  // right: 4.31%;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
