
<template>
  <div class='topview' >
    <router-link to="/home" class="jello-horizontal a">首页<span></span></router-link>
    <router-link to="/rent" class="jello-horizontal b">租将军介绍<span></span></router-link>
    <router-link to="/product" class="jello-horizontal c">产品介绍<span></span></router-link>
    <router-link to="/contact" class="jello-horizontal d">联系我们<span></span></router-link>
  </div>
</template>

<script>
/* eslint-disable */
import $ from 'jquery';
export default {
  /* eslint-disable */
  name: 'topview',
  components: {},
  data() {
    return {
      hrefT: 11
    }
  },
  mounted () {
    // console.log(window.location.href);
    // console.log(this.$route.path);
    window.addEventListener("click", this.clickOther, this.canActivate);

    $('.jello-horizontal').click(function () {
      console.log(this.href)
      this.hrefT = this.href
      $(this).css('color', 'black').siblings().css('color', 'rgba(0,0,0,0.5)')
      $(this).css('border-bottom-style', 'inset').siblings().css('border-bottom-style', '')
    })
    
  },
  watch: {
    '$route':'clickOther'
  },

  methods: {
    clickOther () {
      if(this.$route.path === '/home'){
          $('.jello-horizontal').css('color', '')
          $('.jello-horizontal').css('border-bottom-style','')
          $('.a').css('color', 'black')
          $('.a').css('border-bottom-style','inset')
      }else if(this.$route.path === '/rent'){
          $('.jello-horizontal').css('color', '')
          $('.jello-horizontal').css('border-bottom-style','')
          $('.b').css('color', 'black')
          $('.b').css('border-bottom-style','inset')
      }else if(this.$route.path === '/product'){
          $('.jello-horizontal').css('color', '')
          $('.jello-horizontal').css('border-bottom-style','')
          $('.c').css('color', 'black')
          $('.c').css('border-bottom-style','inset')
      }else if(this.$route.path === '/contact'){
          $('.jello-horizontal').css('color', '')
          $('.jello-horizontal').css('border-bottom-style','')
          $('.d').css('color', 'black')
          $('.d').css('border-bottom-style','inset')  
      }
    },
  }
}
</script>

<style lang="less">
.topview{ 
  width: 100%;
  box-sizing: border-box;
  padding-top: 1.75rem;
  padding: 1.75rem 0 1.65rem 0;
  font-size: 0.7rem;
  text-align: center;
    a{
      text-decoration:none;
      height: 1rem;
      display: inline-block;
      color: rgba(0, 0, 0, 0.5);
      margin-left: 3rem;
      position: relative;
      span{
        display: inline-block;
        width: 100%;
        height: 0.1rem;
        position: absolute;
        top: 1.2rem;
        left: 0rem;
      }
    }
    a:first-child{
      margin-left: 0;
    }
    a:hover{
      color: #000;
    }
}
.jello-horizontal:hover {
	-webkit-animation: jello-horizontal 0.9s both;
	        animation: jello-horizontal 0.9s both;
}
/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
</style>
